<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.7527 35C12.7527 35 25.5053 22.2727 25.5053 12.7273C25.5053 5.69816 19.7958 0 12.7527 0C5.70952 0 0 5.69816 0 12.7273C0 22.2727 12.7527 35 12.7527 35ZM17.5349 12.7273C17.5349 15.3632 15.3938 17.5 12.7527 17.5C10.1115 17.5 7.97041 15.3632 7.97041 12.7273C7.97041 10.0914 10.1115 7.95455 12.7527 7.95455C15.3938 7.95455 17.5349 10.0914 17.5349 12.7273Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
    size: {
      type: Number,
      default: 48,
    },
  },
};
</script>
