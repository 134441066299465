<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_b_28_262)">
      <path
        d="M16.4673 11.4873C17.6668 12.8822 18.4428 14.1501 19.2134 15.727C19.7408 16.8063 19.578 18.0892 18.8507 19.0454C18.0365 20.1159 17.9435 21.5828 18.682 22.7069C20.637 25.6826 22.4495 27.5863 25.1759 29.5005C26.4335 30.3835 28.0292 29.9246 29.4354 29.3052C30.0698 29.0257 30.8461 29.0814 31.4801 29.3617C33.5224 30.265 34.9206 31.0446 36.3345 32.1825C37.7109 33.2902 38.4022 35.3019 37.3486 36.7202C36.802 37.456 36.022 37.9847 34.7785 38.4125C34.1074 38.6434 33.3816 38.662 32.6866 38.5182C29.8142 37.9237 27.9877 37.0769 25.0828 35.0277C20.0919 31.2641 17.5227 28.921 13.7133 23.9546C12.0544 21.0903 11.1231 19.0662 10.4219 16.6709C9.74185 14.3481 9.69291 11.0868 11.8203 9.93284C13.465 9.04076 15.2474 10.0686 16.4673 11.4873Z"
        :fill="color"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_28_262"
        x="6.00452"
        y="5.59952"
        width="35.8553"
        height="37.0103"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="2" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_28_262"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_28_262"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
    size: {
      type: Number,
      default: 48,
    },
  },
};
</script>
